<template>
    <div class="area-impressao bg-white p-3" style="width: 430px; font-family: Arial; font-weight: bold;">

        <!-- #region filial -->
        <div>
            <!--<b-img v-if="filial.fotoBase64" class="float-right ml-3" :src="$vsDefineFoto(filial.fotoBase64)" width="50" height="70" fluid />-->
            <div>{{ filial.nome.toUpperCase() }}</div>
            <div>CNPJ: {{ $vsFormataCpfCnpj(filial.cpfCnpj) }}</div>
            <div>{{ filial.endereco.logradouro.toUpperCase() }}, {{ filial.endereco.numero }}</div>
            <div>CEP: {{ $vsFormataCep(filial.endereco.cep) }} - {{ filial.endereco.cidade.toUpperCase() }}/{{ filial.endereco.uf.toUpperCase() }}</div>
            <div>{{ $vsFormataTelefone(filial.telefone) }}</div>
        </div>
        <!-- #endregion -->
        <!-- #region operador -->
        <div>============================================</div>
        <div>
            <div>DATA: {{ $vsFormataData(postagem.dataCriacao, true) }}</div>
            <div>ATENDIMENTO: {{ postagem.comprovante }}</div>
            <div>OPERADOR: {{ operador.nome.toUpperCase() }}</div>
        </div>
        <!-- #endregion -->
        <!-- #region operacoes (multi-atendimento/multi-volume) -->
        <div v-for="(opr, i) in postagem.operacoes.filter(el => !el.isCancelada)" :key="opr.id">
            <!-- #region remetente -->
            <div v-if="isOutroRemetente(i)">
                <div>============================================</div>
                <div>REMETENTE: {{ opr.remetente.nome.toUpperCase() }}</div>
                <div>CNPJ/CPF: {{ $vsFormataCpfCnpj(opr.remetente.cpfCnpj) }} CEP: {{ $vsFormataCep(opr.remetente.endereco.cep) }}</div>
            </div>
            <!-- #endregion -->
            <!-- #region destinatario -->
            <div>============================================</div>
            <div>ITEM: #{{ i + 1 }}</div>
            <div>
                <div>DESTINATÁRIO: {{ opr.destinatario.nome.toUpperCase() }}</div>
                <div>CNPJ/CPF: {{ $vsFormataCpfCnpj(opr.destinatario.cpfCnpj) }} CEP: {{ $vsFormataCep(opr.destinatario.endereco.cep) }}</div>
            </div>
            <!-- #endregion -->
            <!-- #region volumes -->
            <div v-for="(vol, j) in opr.volumes.filter(el => !el.isCancelado)" :key="vol.id">
                <!--<div>---------------------------------------------------------------------</div>-->
                <div>--------------------------------------------</div>
                <div>VOLUME: {{ j + 1 }}/{{ opr.volumes.filter(el => !el.isCancelado).length }}</div>
                <div>MODAL: {{ vol.servicoDescricao }}</div>
                <div>CÓDIGO DE RASTREIO: {{ vol.etiqueta }}</div>
                <div v-if="montaAdicionais(vol).length">ADICIONAIS: {{ montaAdicionais(vol) }}</div>
                <div v-if="vol.valorDeclarado && vol.valorDeclarado > 0">
                    VALOR DECLARADO: <span class="float-right">{{ $vsFormataMoeda(vol.valorDeclarado) }}</span>
                </div>
                <div v-if="vol.numeroNotaFiscal.length">NF: {{ vol.numeroNotaFiscal }}</div>
                <div v-if="vol.valorNotaFiscal && vol.valorNotaFiscal > 0">
                    VALOR NF: <span class="float-right">{{ $vsFormataMoeda(vol.valorNotaFiscal) }}</span>
                </div>
                <div>PESO (g): <span class="float-right">{{ vol.peso }}</span></div>
                <div>DIMENSÕES (AxLxC cm): <span class="float-right">{{ vol.altura }}x{{ vol.largura }}x{{ vol.comprimento }}</span></div>
                <div v-if="vol.observacoes[0].length">OBS: {{ vol.observacoes[0] }}</div>
                <div>VALOR: <span class="float-right">{{ $vsFormataMoeda(vol.valorFrete) }}</span></div>
            </div>
            <!--<div>---------------------------------------------------------------------</div>-->
            <div>--------------------------------------------</div>
            <div>
                <div>SUB-TOTAL: <span class="float-right">{{ $vsFormataMoeda(calculaTotalFrete(opr)) }}</span></div>
            </div>
            <!-- #endregion -->
        </div>
        <!-- #endregion -->
        <!-- #region remetente/pagador quando nao tem operacoes -->
        <div v-if="!postagem.operacoes || postagem.operacoes.length === 0">
            <div>============================================</div>
            <div>REMETENTE: {{ pagador.nome.toUpperCase() }}</div>
            <div>CNPJ/CPF: {{ $vsFormataCpfCnpj(pagador.cpfCnpj) }} CEP: {{ $vsFormataCep(pagador.endereco.cep) }}</div>
        </div>
        <!-- #endregion -->
        <!-- #region produtos/servicos -->
        <div v-if="this.postagem.produtosServicos && this.postagem.produtosServicos.length > 0">
            <div>============================================</div>
            <div>PRODUTOS/SERVIÇOS</div>
            <div v-for="(srv, i) in produtosServicos()" :key="srv.id">
                <!--<div v-if="i > 0">---------------------------------------------------------------------</div>-->
                <div v-if="i > 0">--------------------------------------------</div>
                <div>DESCRIÇÃO: {{ srv.descricao }}</div>
                <div>QTD: <span class="float-right">{{ srv.quantidade }}</span></div>
                <div>VALOR: <span class="float-right">{{ $vsFormataMoeda(srv.valorTotal) }}</span></div>
                <div v-if="srv.numeroEncomenda.length">Nº ENCOMENDA: {{ srv.numeroEncomenda }}</div>
            </div>
            <!--<div>---------------------------------------------------------------------</div>-->
            <div>--------------------------------------------</div>
            <div>
                <div>SUB-TOTAL: <span class="float-right">{{ $vsFormataMoeda(calculaTotalServico()) }}</span></div>
            </div>
        </div>
        <!-- #endregion -->
        <!-- #region total -->
        <div>============================================</div>
        <!--<div v-if="postagem.formaPagamento.valorDescontoPercentual">
        <div>TOTAL: <span class="float-right">{{ $vsFormataMoeda(postagem.formaPagamento.valorBruto) }}</span></div>
        <div>DESCONTO:({{ $vsFormataMoeda(postagem.formaPagamento.valorDescontoPercentual) }}%) <span class="float-right">-{{ $vsFormataMoeda(postagem.formaPagamento.valorDescontoMonetario) }}</span></div>
        <div>============================================</div>
        <div>TOTAL COM DESCONTO: <span class="float-right">{{ $vsFormataMoeda(postagem.formaPagamento.valorTotal) }}</span></div>
        </div>-->
        <div>
            <div>TOTAL: <span class="float-right">{{ $vsFormataMoeda(postagem.formaPagamento.valorTotal) }}</span></div>
        </div>
        <!-- #endregion -->
        <!-- #region observacoes -->
        <div>============================================</div>
        <div class="nova-pagina">
            <div>{{ filial.mensagemCustomizada.toUpperCase() }}</div>
            <div>Rastreamento: {{ urlRastreio }}<br>*Relatório para simples conferência</div>
        </div>
        <!-- #endregion -->
    </div>
</template>

<script>
export default {
  // #region configs
  name: "vs-recibo-postagem",
  props: {
    filial: { type: Object, required: true },
    operador: { type: Object, required: true },
    postagem: { type: Object, required: true },
		pagador: { type: Object, required: true },
  },
  // #endregion

  // #region dados
  data() {
    return {
      urlRastreio: `${window.location.hostname}/#/rastreio`,
    }
  },
  // #endregion

  // #region metodos
  methods: {
    isOutroRemetente(index) {
      if (index === 0) {
        return true
      }

      let post = this.postagem
      let oprAnterior = post.operacoes[index - 1]
      let oprAtual = post.operacoes[index]

      return (oprAnterior.idPagador !== oprAtual.idPagador)
    },
    montaAdicionais(vol) {
      return vol.adicionais ? vol.adicionais.join(", ") : ""
    },
    produtosServicos() {
      let encomendasExternas = this.postagem.produtosServicos.filter(el => el.ativo && !el.cancelado && el.numeroEncomenda && el.numeroEncomenda.length)
      let produtos = this.postagem.produtosServicos.filter(el => el.ativo && !el.cancelado && (!el.numeroEncomenda || !el.numeroEncomenda.length))
      let qtd = 0
      let encomendas = []
      let vtotal = 0
      let desc = ''

      encomendasExternas.forEach((a) => {
        qtd++
        vtotal += a.valorTotal
        desc = a.descricao
        encomendas.push(a.numeroEncomenda)
      })

      produtos.push({
        descricao: desc,
        quantidade: qtd,
        valorTotal: vtotal,
        numeroEncomenda: encomendas.join(", ")
      })

      // remove totalmente zerados/vazios
      produtos = produtos.filter(el =>
        el.descricao.length
        || el.quantidade > 0
        || el.valorTotal > 0
        || el.numeroEncomenda.length)
        
      return produtos
    },
    calculaTotalFrete(operacao) {
      return operacao.volumes.filter(el => !el.isCancelado).reduce((total, v) => total += v.valorFrete, 0)
    },
    calculaTotalServico() {
      return this.postagem.produtosServicos.filter(el => el.ativo && !el.cancelado).reduce((total, p) => total += p.valorTotal, 0)
    },
  },
  // #endregion
}
</script>
